<template>
  <div>
    <div v-if="!isLoading">
      <div class="grid xl:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 mt-4" v-if="vouchers.length > 0">
        <div v-for="voucher in vouchers" :key="voucher.id">
          <div class="rounded-3xl hover:shadow-lg">
            <img :src="voucher.foto" class="rounded-t-3xl h-28 cursor-pointer w-full object-cover maskImage" @click="openDetailPage(voucher)" />
            <div class="px-4 shadow-lg rounded-3xl h-12 grid grid-cols-2">
              <div class="flex items-center">
                <img src="@/assets/icons/rp.png" class="mr-2" />
                <div class="text-xs">
                  <div class="font-semibold text-gray-500">Min. Transaction</div>
                  <div class="text-primary font-bold"> {{ voucher.minimal | toCurrency }}</div>
                </div>
              </div>
              <div class="flex items-center justify-end">
                <button class="text-sm text-white py-1 px-6 bg-primary font-semibold rounded-lg" @click="claimVoucher(voucher.kode_voucher)">Claim</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-4 text-center font-bold md:text-xl" v-else>
        There's no available voucher to claim
      </div>
    </div>
    <div v-else>
      <div class="grid xl:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 mt-4">
        <div class="rounded-3xl">
          <div class="rounded-t-3xl bg-gray-300 transition-all duration-300 animate-pulse w-full h-28 maskImage"></div>
          <div class="rounded-b-3xl bg-gray-200 transition-all duration-300 animate-pulse w-full h-12">
          </div>
        </div>
        <div class="rounded-3xl">
          <div class="rounded-t-3xl bg-gray-300 transition-all duration-300 animate-pulse w-full h-28 maskImage"></div>
          <div class="rounded-b-3xl bg-gray-200 transition-all duration-300 animate-pulse w-full h-12">
          </div>
        </div>
        <div class="rounded-3xl">
          <div class="rounded-t-3xl bg-gray-300 transition-all duration-300 animate-pulse w-full h-28 maskImage"></div>
          <div class="rounded-b-3xl bg-gray-200 transition-all duration-300 animate-pulse w-full h-12">
          </div>
        </div>
        <div class="rounded-3xl">
          <div class="rounded-t-3xl bg-gray-300 transition-all duration-300 animate-pulse w-full h-28 maskImage"></div>
          <div class="rounded-b-3xl bg-gray-200 transition-all duration-300 animate-pulse w-full h-12">
          </div>
        </div>
        <div class="rounded-3xl">
          <div class="rounded-t-3xl bg-gray-300 transition-all duration-300 animate-pulse w-full h-28 maskImage"></div>
          <div class="rounded-b-3xl bg-gray-200 transition-all duration-300 animate-pulse w-full h-12">
          </div>
        </div>
        <div class="rounded-3xl">
          <div class="rounded-t-3xl bg-gray-300 transition-all duration-300 animate-pulse w-full h-28 maskImage"></div>
          <div class="rounded-b-3xl bg-gray-200 transition-all duration-300 animate-pulse w-full h-12">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AccountVoucherCardClaim',
  data() {
    return {
      isLoading: false,
      vouchers: []
    }
  },
  async created() {
    this.isLoading = true

    try {
      const response = await this.$http.get(`${this.$apiTripweWeb}/voucher/list/claimable`);
      this.vouchers = response.data.data;
    } catch (error) {
      this.$toasted.global.error(error);
    }

    this.isLoading = false
  },
  methods: {
    openDetailPage(voucher) {
      this.$router.push({ name: 'AccountVoucherDetail', params: { voucher } });
    },
    async claimVoucher(kode_voucher) {
      try {
        const response = await this.$http.post(`${this.$apiTripweWeb}/voucher/claim`, {
          code: kode_voucher
        });
        if (response.data.status == 200){
          this.$toasted.global.success(response.data.message)
        } else {
          this.$toasted.global.error(response.data.message)
        }
      } catch (error) {
        this.$toasted.global.error(error);
      }
    }
  }
};
</script>
