<template>
    <div>
        <div class="flex flex-row justify-between md:justify-start pb-2.5 border-b-2 border-grey-3 text-center">
            <div class="font-medium text-base md:text-xl text-center md:mr-20 md:ml-7 cursor-pointer" @click="openTab(1)"
                :class="tab == 1 ? 'font-bold text-primary' : ''">Claim Voucher</div>
            <div class="font-medium text-base md:text-xl text-center cursor-pointer" @click="openTab(2)"
                :class="tab == 2 ? 'font-bold text-primary' : ''">My Voucher</div>
        </div>
        <div v-if="tab == 1">
            <AccountVoucherCardClaim />
        </div>
        <div v-if="tab == 2">
            <AccountVoucherCard />
        </div>
    </div>
</template>

<script>
import AccountVoucherCard from '@/components/account/VoucherCard.vue';
import AccountVoucherCardClaim from '@/components/account/VoucherCardClaim.vue';

export default {
    name: 'AccountVoucherIndex',

    components: {
        AccountVoucherCard, AccountVoucherCardClaim
    },

    data() {
        return {
            tab: 2,
            vouchers: []
        };
    },

    async created() {
        this.$store.commit('setBreadcrumbItems', [
            { text: 'Home', routeName: 'Home' },
            { text: 'Account', routeName: 'AccountProfile' },
            { text: 'Voucher', routeName: 'AccountVoucherIndex' }
        ]);
    },

    methods: {
        openTab(tab) {
            this.tab = tab
        }
    }
};
</script>
