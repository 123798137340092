<template>
  <div>
    <div class="flex flex-col md:flex-row mt-4 mb-2.5">
      <input type="text" placeholder="Enter your voucher code" :value="form" maximum-scale=1 @input="form = $event.target.value.toUpperCase()"
        class="text-sm text-rounded font-medium py-3 px-8 border border-rounded rounded-lg">
      <button class="font-semibold text-white bg-primary mt-2 md:mt-0 md:ml-5 py-2.5 px-8 rounded-lg" @click="claimVoucher">Claim</button>
    </div>
    <div v-if="!isLoading">
      <div class="grid xl:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 mt-4" v-if="vouchers.length > 0">
        <div v-for="voucher in vouchers" :key="voucher.id" @click="openDetailPage(voucher)">
          <div class="rounded-3xl cursor-pointer hover:shadow-lg">
            <img :src="voucher.foto" class="rounded-t-3xl h-28 w-full object-cover maskImage" />
            <div class="px-4 shadow-lg rounded-3xl h-12 grid grid-cols-2">
              <div class="flex items-center">
                <font-awesome-icon class="mr-2" icon="fa-regular fa-clock" size="lg" />
                <div class="text-xs">
                  <div class="font-semibold text-gray-500">Valid until</div>
                  <div class="text-primary font-bold">{{ $moment(voucher.waktu_selesai).format('D MMMM YYYY') }}</div>
                </div>
              </div>
              <div class="flex items-center justify-end">
                <img src="@/assets/icons/rp.png" class="mr-2" />
                <div class="text-xs">
                  <div class="font-semibold text-gray-500">Min. Transaction</div>
                  <div class="text-primary font-bold"> {{ voucher.minimal | toCurrency }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-4 text-center font-bold md:text-xl" v-else>
        You did'nt have any voucher yet...
      </div>
    </div>
    <div v-else>
      <div class="grid xl:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 mt-4">
        <div class="rounded-3xl">
          <div class="rounded-t-3xl bg-gray-300 transition-all duration-300 animate-pulse w-full h-28 maskImage"></div>
          <div class="rounded-b-3xl bg-gray-200 transition-all duration-300 animate-pulse w-full h-12">
          </div>
        </div>
        <div class="rounded-3xl">
          <div class="rounded-t-3xl bg-gray-300 transition-all duration-300 animate-pulse w-full h-28 maskImage"></div>
          <div class="rounded-b-3xl bg-gray-200 transition-all duration-300 animate-pulse w-full h-12">
          </div>
        </div>
        <div class="rounded-3xl">
          <div class="rounded-t-3xl bg-gray-300 transition-all duration-300 animate-pulse w-full h-28 maskImage"></div>
          <div class="rounded-b-3xl bg-gray-200 transition-all duration-300 animate-pulse w-full h-12">
          </div>
        </div>
        <div class="rounded-3xl">
          <div class="rounded-t-3xl bg-gray-300 transition-all duration-300 animate-pulse w-full h-28 maskImage"></div>
          <div class="rounded-b-3xl bg-gray-200 transition-all duration-300 animate-pulse w-full h-12">
          </div>
        </div>
        <div class="rounded-3xl">
          <div class="rounded-t-3xl bg-gray-300 transition-all duration-300 animate-pulse w-full h-28 maskImage"></div>
          <div class="rounded-b-3xl bg-gray-200 transition-all duration-300 animate-pulse w-full h-12">
          </div>
        </div>
        <div class="rounded-3xl">
          <div class="rounded-t-3xl bg-gray-300 transition-all duration-300 animate-pulse w-full h-28 maskImage"></div>
          <div class="rounded-b-3xl bg-gray-200 transition-all duration-300 animate-pulse w-full h-12">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AccountVoucherCard',
  data() {
    return {
      form: null,
      isLoading: false,
      vouchers: []
    }
  },
  async created() {
    this.loadVoucher();
  },
  methods: {
    openDetailPage(voucher) {
      this.$router.push({ name: 'AccountVoucherDetail', params: { voucher } });
    },
    async loadVoucher(){
      this.isLoading = true

      try {
        const response = await this.$http.get(`${this.$apiTripweWeb}/voucher/list`);
        this.vouchers = response.data.data;
      } catch (error) {
        this.$toasted.global.error(error);
      }

      this.isLoading = false
    },
    async claimVoucher(){
      try {
        const response = await this.$http.post(`${this.$apiTripweWeb}/voucher/claim`, {
          code: this.form
        })
        if (response.data.status == 200){
          this.$toasted.global.success(response.data.message)
          await this.loadVoucher();
        } else {
          this.$toasted.global.error(response.data.message)
        }
        this.form = "";
      } catch (error) {
        this.$toasted.global.error(error);
      }
    }
  }
};
</script>
